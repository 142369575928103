import React, { FC } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import LinkButton from '../components/LinkButton';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-30%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFDF3',
    borderRadius: '1rem',
    maxWidth: 469,
  },
};

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  outline: 0;
  text-align: center;
  width: 100%;
  border: none;
`;

const Heading = styled.h2`
  font-weight: 900;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1.41;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 1.44;
  }
`;

const Paragraph = styled.p`
  margin-top: 22px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 400px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 16px;
    line-height: 1.64;
  }
`;

const Link = styled(LinkButton)`
  margin-top: 32px;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const Modal: FC<{
  title: string;
  show: boolean;
  onClose: () => void;
  buttonText: string;
}> = ({ title, show, onClose, buttonText, children }) => {
  return (
    <ReactModal isOpen={show} onRequestClose={onClose} contentLabel='Modal' style={modalStyles}>
      <Layout>
        <Heading>{title}</Heading>
        <Paragraph>{children}</Paragraph>
        <Link onClick={onClose}>{buttonText}</Link>
      </Layout>
    </ReactModal>
  );
};

export default Modal;
