import { motion } from 'framer-motion';
import styled from 'styled-components';

const Card = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  width: 275px;
  background-color: ${(props) => props.theme.colors.cloudBlue};
  box-shadow: 12px 12px 0px ${(props) => props.theme.colors.lightPeach};
  border-radius: 12px;
`;

export default Card;
