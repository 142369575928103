export const EVENT_TRACKS = {
  CLICK_GET_STARTED: {
    name: 'click get started - marketing home',
  },
  CLICK_START_GROUP_CARD: {
    name: 'click start group card - marketing home',
  },
  CLICK_B2B_PRICING_PLAN: {
    name: 'b2b pricing plan click',
  },
  CLICK_B2C_PRICING_PLAN: {
    name: 'b2c pricing plan click',
  },
};
