import React, { FC } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Bubble from '../components/Bubble';
import Wave from '../components/Wave';

import noteIcon from '../icons/note.svg';
import peopleIcon from '../icons/people.svg';
import sendIcon from '../icons/send.svg';

const Layout = styled.section`
  display: flex;
  padding: 53px 10% 21px;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 0 50px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
  }
`;

const WaveEnhanced = styled(Wave)`
  position: absolute;
  bottom: 0;
  margin-bottom: -2%;
  left: 0;
  width: 100%;
`;

const Container = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};
  width: 100%;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
  }
`;

const BubbleEnhanced = styled(Bubble)`
  margin: 30px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 20px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ThreeBubbles: FC = () => {
  return (
    <Layout>
      <Container>
        <BubbleEnhanced
          iconUrl={noteIcon}
          title='#1. create a card'
          text='generate a shareable link to your group card—it takes 30 seconds, seriously.'
          custom={1}
        />
        <BubbleEnhanced
          iconUrl={peopleIcon}
          title='#2. invite others'
          text='send the link to others and invite them to add their own messages & photos.'
          custom={2}
        />
        <BubbleEnhanced
          iconUrl={sendIcon}
          title='#3. send!'
          text='share the group card with the recipient and we guarantee it will make their day.'
          custom={3}
        />
      </Container>
      <WaveEnhanced />
    </Layout>
  );
};

export default ThreeBubbles;
