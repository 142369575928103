import React, { FC, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import Card from './Card';

const EnhancedCard = styled(Card)`
  padding: 30px 20px;
  flex-direction: column;
  background: ${(props) => props.theme.colors.lightPeach2};
  box-shadow: 12px 12px 0px ${(props) => props.theme.colors.lightPeach};
  width: 225px;
  min-height: 325px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 275px;
    min-height: 350px;
  }
`;

const Gap = styled.div`
  flex-grow: 1;
`;

const Author = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: ${(props) => props.theme.colors.black};
  text-transform: lowercase;
`;

const StripWrapper = styled.div`
  position: relative;
  margin-top: 6px;
`;

const Paragraph = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 2.5;
  color: ${(props) => props.theme.colors.blackText};

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 16px;
    line-height: 1.86;
  }
`;

const Bar = styled.div`
  background-color: ${(props) => props.theme.colors.orange};
  border-radius: 4px;
  height: 30px;
  width: 100%;
`;

const StripBlock = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
`;

const Strip: FC<{
  text: string;
  width?: string;
}> = ({ text, width = '100%' }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const barVariants = {
    hidden: {
      width: 0,
      translateZ: 0,
    },
    visible: {
      width: width,
      translateZ: 0,
      transition: {
        duration: 1.1,
        ease: 'anticipate',
      },
    },
  };

  return (
    <StripWrapper>
      <Paragraph>{text}</Paragraph>
      <StripBlock ref={ref} variants={barVariants} initial='hidden' animate={controls}>
        <Bar />
      </StripBlock>
    </StripWrapper>
  );
};

const CardB: FC<{
  author: string;
}> = ({ author }) => (
  <EnhancedCard>
    <Strip text='You are just a beautiful human being.' />
    <Strip text='I appreciate how deeply you feel' />
    <Strip text='and how empathetic you are towards' />
    <Strip text='others. You embody that little' />
    <Strip text='bright light :)' width='40%' />
    <Gap />
    <Author>{author}</Author>
  </EnhancedCard>
);

export default CardB;
