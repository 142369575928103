import React, { FC, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import { isMobile } from '../helpers/utils';

import CardC from '../components/cards/CardC';
import Footer from '../components/Footer';

import image1 from '../images/optimized/section-e-small-1.jpg';
import image2 from '../images/optimized/section-e-small-2.jpg';
import image3 from '../images/optimized/section-e-small-3.jpg';

const Layout = styled.section`
  display: flex;
  padding: 71px 10% 279px;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 50px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-bottom: 100px;
  }
`;

const Heading = styled.h2`
  font-weight: 900;
  font-size: 22px;
  line-height: 1.44;
  color: ${(props) => props.theme.colors.blackText};
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    text-align: left;
  }
`;

const CardsContainer = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    align-items: stretch;
    margin-top: 32px;
  }
`;

const CardEnhanced = styled(CardC)`
  margin-top: 32px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: 0;
  }
`;

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.3,
    },
  },
};

const itemMobile = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { type: 'spring', bounce: 0.38, duration: 1.3 } },
};

const itemDesktop = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { type: 'spring', bounce: 0.38, duration: 1.3 } },
};

const Testimonials: FC = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const itemVariant = isMobile() ? itemMobile : itemDesktop;

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <Layout>
      <Container>
        <Heading>Testimonials</Heading>
        <CardsContainer variants={container} initial='hidden' animate={controls} ref={ref}>
          <CardEnhanced
            imageUrl={image1}
            name='Belén Ortiz'
            quote='“This is such a simple nice idea to help people feel seen and appreciated. Easy to use, simple but great impact!”'
            color='pink'
            variants={itemVariant}
          />
          <CardEnhanced
            imageUrl={image2}
            name='Dr. Lavi'
            quote='“I truly appreciate all the beautiful and kind words...It touched my soul and heart!”'
            color='blue'
            variants={itemVariant}
          />
          <CardEnhanced
            imageUrl={image3}
            name='Christina H.'
            quote='“illume is friendly to navigate and easy to use. Would highly recommend it 🙌🙌.”'
            color='olive'
            variants={itemVariant}
          />
        </CardsContainer>
      </Container>
      <Footer />
    </Layout>
  );
};

export default Testimonials;
