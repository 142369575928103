import React, { CSSProperties, FC, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import Card from './Card';
import ShapeImage from '../ShapeImage';

const CardEnhanced = styled(Card)`
  width: 250px;
  height: 370px;
  transition: all 0.3s ease;

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 275px;
  }
`;

const SingleImage = styled(ShapeImage)`
  height: 160px;
  width: 160px;
  align-self: center;
`;

const ImageContainer = styled.div`
  height: 160px;
  width: 160px;
  position: relative;
  align-self: center;
`;

const MultiImage1 = styled(ShapeImage)`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 0;
`;

const MultiImage2 = styled(ShapeImage)`
  height: 70px;
  width: 70px;
  position: absolute;
  top: 75px;
  left: 80px;
`;

const MultiImage3 = styled(ShapeImage)`
  height: 62px;
  width: 62px;
  position: absolute;
  top: 10px;
  left: 100px;
`;

const Quote = styled.p`
  margin-top: 6px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.olive};
`;

const Author = styled.h3`
  margin-top: 32px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  color: ${(props) => props.theme.colors.black};
`;

const CardA: FC<{
  imageUrl?: string;
  imageUrl2?: string;
  imageUrl3?: string;
  color?: 'blue' | 'salmon' | 'oldGold' | 'dustStorm';
  quote: string;
  author: string;
  className?: string;
  id?: string;
}> = ({ id, className, imageUrl, quote, author, imageUrl2, imageUrl3, color = 'blue' }) => {
  const {
    colors: { cloudBlue, olive, salmon2, hemlock, oldGold, alabaster, dustStorm, comet },
  } = useTheme();
  const [cardStyle, setCardStyle] = useState<CSSProperties>({});
  const [quoteStyle, setQuoteStyle] = useState<CSSProperties>({});

  useEffect(() => {
    switch (color) {
      case 'blue': {
        setCardStyle({
          backgroundColor: cloudBlue,
        });
        setQuoteStyle({
          color: olive,
        });
        break;
      }
      case 'salmon': {
        setCardStyle({
          backgroundColor: salmon2,
        });
        setQuoteStyle({
          color: hemlock,
        });
        break;
      }
      case 'oldGold': {
        setCardStyle({
          backgroundColor: oldGold,
        });
        setQuoteStyle({
          color: alabaster,
        });
        break;
      }
      case 'dustStorm': {
        setCardStyle({
          backgroundColor: dustStorm,
        });
        setQuoteStyle({
          color: comet,
        });
        break;
      }
      default: {
        break;
      }
    }
  }, [alabaster, cloudBlue, color, comet, dustStorm, hemlock, oldGold, olive, salmon2]);

  return (
    <CardEnhanced id={id} className={className} style={cardStyle}>
      {imageUrl && imageUrl2 && imageUrl3 ? (
        <ImageContainer>
          <MultiImage1 imageUrl={imageUrl} />
          <MultiImage2 imageUrl={imageUrl2} />
          <MultiImage3 imageUrl={imageUrl3} />
        </ImageContainer>
      ) : imageUrl ? (
        <SingleImage imageUrl={imageUrl} />
      ) : (
        <></>
      )}
      <Quote style={quoteStyle}>{quote}</Quote>
      <Author>{author}</Author>
    </CardEnhanced>
  );
};

export default CardA;
