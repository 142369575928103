import React, { FC, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import ShapeImage from '../components/ShapeImage';
import Shape from '../components/Shape';
import Wave from '../components/Wave';

import image1Url from '../images/section-b-small-1.jpg';
import image2Url from '../images/section-b-small-2.png';
import image3Url from '../images/section-b-small-3.png';
import image4Url from '../images/section-b-small-4.png';
import { isMobile } from '../helpers/utils';

const Layout = styled.section`
  display: flex;
  padding: 82px 8% 130px;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 50px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
  }
`;

const WaveEnhanced = styled(Wave)`
  position: absolute;
  bottom: 0;
  margin-bottom: -2%;
  left: 0;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
  }
`;

const LeftSection = styled.section`
  display: flex;
  flex-direction: column;
  order: 2;
  text-align: center;
  margin-top: 45px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 1;
    max-width: 50%;
    text-align: left;
    margin-top: 0;
  }
`;

const RightSection = styled.section`
  position: relative;
  order: 1;
  right: -45px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 2;
    margin-left: 120px;
  }
`;

const Paragraph = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.blackText};
  max-width: 400px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 22px;
    line-height: 1.64;
  }
`;

const Heading = styled.h3`
  margin: 44px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.blackText};

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 32px 0;
    font-size: 22px;
    line-height: 1.64;
  }
`;

const Icon = styled(Shape)`
  width: 80px;
  height: 80px;
  margin: 0 3px;
`;

const IconText = styled.div`
  color: ${(props) => props.theme.colors.lightPeach2};
  text-transform: uppercase;
`;

const Image = styled(ShapeImage)`
  display: block;
  height: 180px;
  width: 180px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 280px;
    width: 280px;
  }
`;

const Image1 = styled(Image)`
  position: relative;
  top: 0px;
  left: 0;
`;

const Image2 = styled(Image)`
  position: absolute;
  top: 12px;
  left: -26px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    top: 18px;
    left: -39px;
  }
`;

const Image3 = styled(Image)`
  position: absolute;
  top: -10px;
  left: -53px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    top: -16px;
    left: -78px;
  }
`;

const Image4 = styled(Image)`
  position: absolute;
  top: 2px;
  left: -79px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    top: 3px;
    left: -117px;
  }
`;

const bubbleMobile = {
  hidden: { opacity: 0.75, scale: 0.6 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.8,
    },
  },
};

const bubbleDesktop = {
  hidden: { opacity: 0.75, scale: 0.6 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      delay: 1.2,
    },
  },
};

const container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.24,
      delayChildren: 0.3,
    },
  },
};

const item = {
  hidden: { opacity: 0, scale: 0.6 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: 'spring', bounce: 0.6 },
  },
};

const ImageGroup: FC = () => {
  const theme = useTheme();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.div variants={container} initial='hidden' animate={controls} ref={ref}>
      <Image4 imageUrl={image4Url} shadowColor={theme.colors.salmon} variants={item} />
      <Image3 imageUrl={image3Url} shadowColor={theme.colors.cloudBlue} variants={item} />
      <Image2 imageUrl={image2Url} shadowColor={theme.colors.lightOlive} variants={item} />
      <Image1 imageUrl={image1Url} shadowColor={theme.colors.pink} variants={item} />
    </motion.div>
  );
};

const WeLoveThatFeeling: FC = () => {
  const theme = useTheme();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 1,
  });
  const bubbleVariant = isMobile() ? bubbleMobile : bubbleDesktop;

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <Layout>
      <Container>
        <LeftSection>
          <Paragraph>
            you know that magical feeling when people
            <br />
            come together to share appreciation &
            <br />
            celebrate those they care about most?
          </Paragraph>
          <Heading ref={ref}>
            we
            <Icon
              color={theme.colors.orange}
              shadowColor={theme.colors.white}
              variants={bubbleVariant}
              initial='hidden'
              animate={controls}
            >
              <IconText>Love</IconText>
            </Icon>
            that feeling.
          </Heading>
          <Paragraph>
            that&apos;s why we created illume; to reclaim
            <br />
            that togetherness & build a more
            <br />
            emotionally-connected world.
          </Paragraph>
        </LeftSection>
        <RightSection>
          <ImageGroup />
        </RightSection>
      </Container>
      <WaveEnhanced color='lightPeach2' />
    </Layout>
  );
};

export default WeLoveThatFeeling;
