import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { theme } from '../styles/theme';

import MetaTags from '../components/MetaTags';
import Header from '../components/Header';
import CreateCardButton from '../components/CreateCardButton';

import IntroSection from '../sections/IntroSection';
import ItIsCoolToBeKind from '../sections/ItIsCoolToBeKind';
import WeLoveThatFeeling from '../sections/WeLoveThatFeeling';
import ThreeBubbles from '../sections/ThreeBubbles';
import PrivacyIsAPriority from '../sections/PrivacyIsAPriority';
import Testimonials from '../sections/Testimonials';

import { handleScroll } from '../helpers/scrollTop';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;

  @media ${(props) => props.theme.breakpoints.laptop} {
    overflow: scroll;
    height: 100vh;
    -webkit-backface-visibility: hidden;
    scroll-snap-align: start;
  }
`;

const IndexPage: FC = () => {
  const [showIntroSection, setShowIntroSection] = useState<boolean>(true);
  const [introSectionStyle, setIntroSectionStyle] = useState<CSSProperties>({});
  const { ref, inView } = useInView({ threshold: 0.1 });

  useEffect(() => {
    const disableIntroAnimation = localStorage.getItem('disableIntroAnimation');

    if (disableIntroAnimation === 'true') {
      setShowIntroSection(false);
    } else {
      setIntroSectionStyle({
        backgroundColor: theme.colors.orange,
      });
      localStorage.setItem('disableIntroAnimation', 'true');
    }

    handleScroll();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MetaTags title='better together' />
      <Layout>
        {showIntroSection && (
          <IntroSection
            layoutStyle={introSectionStyle}
            onAnimationComplete={() => {
              window.scrollBy({ top: 1 });
            }}
          />
        )}
        <Header />
        <ItIsCoolToBeKind />
        <div ref={ref}>
          <WeLoveThatFeeling />
          <ThreeBubbles />
          <PrivacyIsAPriority />
          <Testimonials />
        </div>
        <CreateCardButton show={inView} />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
