import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';

import LinkButton from '../components/LinkButton';
import CardA from '../components/cards/CardA';
import Wave from '../components/Wave';
import Modal from '../components/Modal';

import analytics from '../helpers/analytics';

import { CREATE_CARD_LINK } from '../constants/links';
import image1 from '../images/optimized/section-a-small-1.jpg';
import image2 from '../images/optimized/section-a-small-2.jpg';
import image3 from '../images/optimized/section-a-small-3.jpg';
import image4 from '../images/optimized/section-a-small-4.jpg';
import { EVENT_TRACKS } from '../constants/eventTracks';

const NOTES_STARTING_COUNT = 20721;

const Layout = styled.section`
  display: flex;
  padding: 32px 10%;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 50px;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
  }
`;

const WaveEnhanced = styled(Wave)`
  position: absolute;
  bottom: 0;
  margin-bottom: -2%;
  left: 0;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
  }
`;

const LeftSection = styled.section`
  display: flex;
  flex-direction: column;
  order: 2;
  margin-top: 48px;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 1;
    margin-top: 0;
    align-items: flex-start;
  }
`;

const RightSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  margin-left: 20px;
  order: 1;
  display: flex;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 2;
    margin-top: 0;
    margin-left: 0;
  }
`;

const CardContainer = styled.div`
  position: relative;
  height: 368px;
  width: 275px;
`;

const CardEnhanced1 = styled(CardA)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 14;
  animation: slide1 20s ease 3s infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
`;

const CardEnhanced2 = styled(CardA)`
  position: absolute;
  top: 0;
  left: 0;
  animation: slide3 20s ease 3s infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
`;

const CardEnhanced3 = styled(CardA)`
  position: absolute;
  top: 0;
  left: 0;
  animation: slide2 20s ease 3s infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
`;

const CardEnhanced4 = styled(CardA)`
  position: absolute;
  top: 0;
  left: 0;
  animation: slide4 20s ease 3s infinite;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
`;

const Heading = styled.h2`
  font-weight: 900;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 1.41;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 1.44;
    text-align: left;
  }
`;

const Paragraph = styled.p`
  margin-top: 16px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 400px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: left;
    font-size: 22px;
    line-height: 1.64;
  }
`;

const ModalButton = styled.p`
  margin-top: 24px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.58;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blackTextLight};
  text-align: center;
  max-width: 400px;
  cursor: pointer;
  margin-left: -20px;
  &:focus {
    outline: none;
  }
  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-left: 20px;
  }
`;

const Link = styled(LinkButton)`
  margin-top: 32px;
  width: 100%;
  max-width: 264px;
`;

const Info = styled.h3`
  margin-top: 8px;
  width: 100%;
  max-width: 264px;
  font-size: 14px;
  line-height: 1.58;
  color: ${(props) => props.theme.colors.blackTextLight};
  text-align: center;
`;

ReactModal.setAppElement(`#___gatsby`);

const ItIsCoolToBeKind: FC = () => {
  const notesRef = useRef<HTMLHeadingElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [notesNumber] = useState<number>(NOTES_STARTING_COUNT);
  // const {
  //   notesApi: { totalNumberOfNotes },
  // } = useStaticQuery(
  //   graphql`
  //     query Notes {
  //       notesApi {
  //         totalNumberOfNotes
  //       }
  //     }
  //   `
  // );

  // useEffect(() => {
  //   const event = () => {
  //     if (notesRef.current) {
  //       const { top } = notesRef.current.getBoundingClientRect();
  //       setNotesInViewport(top - window.innerHeight < 0);
  //     }
  //   };

  //   window.addEventListener('scroll', event);

  //   return () => {
  //     window.removeEventListener('scroll', event);
  //   };
  // }, []);

  // useEffect(() => {
  //   let interval: NodeJS.Timeout | null = null;

  //   if (totalNumberOfNotes > 0 && !interval) {
  //     const step = Math.floor(totalNumberOfNotes / 500);
  //     let current = NOTES_STARTING_COUNT;

  //     interval = setInterval(() => {
  //       current += step;

  //       if (current > totalNumberOfNotes && interval) {
  //         setNotesNumber(totalNumberOfNotes);
  //         clearInterval(interval);
  //         return;
  //       }

  //       setNotesNumber(current);
  //     }, 15);
  //   }

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [totalNumberOfNotes, notesInViewport]);

  const handleClickStart = () => {
    analytics.track(EVENT_TRACKS.CLICK_GET_STARTED.name);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      <Container>
        <LeftSection>
          <Heading>it’s cool to be kind.</Heading>
          <Paragraph>
            With illume, easily create a digital group card for any occasion or just because.
          </Paragraph>
          <Link href={CREATE_CARD_LINK} onClick={handleClickStart}>
            start a group card
          </Link>
          <Info ref={notesRef}>
            {notesNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} notes sent
          </Info>
        </LeftSection>
        <RightSection>
          <CardContainer>
            <CardEnhanced1
              id='x1'
              imageUrl={image1}
              quote='Wherever and whatever you do, continue to emanate love. It’s precious. It’s rare.'
              author='anonymous'
            />
            <CardEnhanced2
              id='x2'
              imageUrl={image4}
              imageUrl2={image2}
              imageUrl3={image3}
              quote='You are a wonder, a brilliant, shining light. A dancer and a lover.'
              author='anonymous'
              color='salmon'
            />
            <CardEnhanced3
              id='x3'
              quote='You have taught me that there is an immense amount of bravery in allowing yourself to be vulnerable and soft. I always used to think that vulnerability was a sign of weakness, but you showed me that it’s actually a sign of strength.'
              author='ariana'
              color='oldGold'
            />
            <CardEnhanced4
              id='x4'
              quote='Thanks for being an awesome example of what it means to be unapologetically yourself. It’s an inspiration to those around you.'
              author='sam'
              color='dustStorm'
            />
          </CardContainer>
          <ModalButton role='button' tabIndex={0} onClick={handleModalOpen}>
            what is a group card?
          </ModalButton>
          <Modal
            show={showModal}
            onClose={handleModalClose}
            title='So, what exactly is a group card?'
            buttonText='got it'
          >
            A group card allows <b>multiple individuals</b> to contribute to a card for{' '}
            <b>one recipient</b>. Each individual note is kept private from the rest of the group so
            only the recipient will see the final result.
            <br />
            <br />
            Digital group cards are great because you can bring people together, no matter where
            they are, to celebrate a <b>birthday</b>, <b>anniversary</b>, or <b>graduation</b>;
            invite a group to <b>share thanks</b>, <b>show care</b>, or <b>congratulate someone</b>.
            The options are endless.
          </Modal>
        </RightSection>
      </Container>
      <WaveEnhanced color='lightPeach' />
    </Layout>
  );
};

export default ItIsCoolToBeKind;
