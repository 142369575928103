import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import LinkButton from '../components/LinkButton';
import CardB from '../components/cards/CardB';
import ShapeImage from '../components/ShapeImage';
import Wave from '../components/Wave';

import imageUrl1 from '../images/optimized/section-d-small-1.jpg';
import imageUrl2 from '../images/optimized/section-d-small-2.jpg';
import imageUrl3 from '../images/optimized/section-d-small-3.jpg';
import imageUrl4 from '../images/optimized/section-d-small-4.jpg';
import imageUrl5 from '../images/optimized/section-d-small-5.jpg';
import imageUrl6 from '../images/optimized/section-d-small-6.jpg';
import imageUrl7 from '../images/optimized/section-d-small-7.jpg';

import { CREATE_CARD_LINK } from '../constants/links';

import analytics from '../helpers/analytics';

const TRACK_START_GROUP_CARD = 'click start group card - marketing home';

const Layout = styled.section`
  display: flex;
  padding: 81px 10% 92px;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.orange};
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 50px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
  }
`;

const WaveEnhanced = styled(Wave)`
  position: absolute;
  bottom: 0;
  margin-bottom: -2%;
  left: 0;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  max-width: ${(props) => props.theme.containerWidthForBreakpoints.laptop};

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
  }
`;

const LeftSection = styled.section`
  display: flex;
  flex-direction: column;
  order: 2;
  margin-top: 55px;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 1;
    max-width: 50%;
    margin-top: 0;
    align-items: flex-start;
  }
`;

const RightSection = styled.section`
  order: 1;

  @media ${(props) => props.theme.breakpoints.laptop} {
    order: 2;
  }
`;

const Heading = styled.h2`
  font-weight: 900;
  font-size: 22px;
  line-height: 1.41;
  color: ${(props) => props.theme.colors.lightPeach2};
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
    line-height: 1.33;
    text-align: left;
  }
`;

const Paragraph = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
  max-width: 400px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: left;
  }
`;

const Link = styled(LinkButton)`
  margin-top: 32px;
  width: 100%;
  max-width: 350px;
  background-color: ${(props) => props.theme.colors.dirtOrange};
  padding: 10px 14px;
`;

const Info = styled.div`
  margin-top: 8px;
  font-size: 14px;
  line-height: 2;
  color: ${(props) => props.theme.colors.blackTextLight};
  width: 100%;
  max-width: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AvatarContainer = styled.div`
  position: relative;
  margin: 10px 10px 0 60px;
`;

const Avatar = styled(ShapeImage)`
  height: 20px;
  width: 20px;
`;

const Avatar1 = styled(Avatar)`
  position: relative;
`;

const Avatar2 = styled(Avatar)`
  position: absolute;
  right: 10px;
`;

const Avatar3 = styled(Avatar)`
  position: absolute;
  right: 20px;
`;

const Avatar4 = styled(Avatar)`
  position: absolute;
  right: 30px;
`;

const Avatar5 = styled(Avatar)`
  position: absolute;
  right: 40px;
`;

const Avatar6 = styled(Avatar)`
  position: absolute;
  right: 50px;
`;

const Avatar7 = styled(Avatar)`
  position: absolute;
  right: 60px;
`;

const PrivacyIsAPriority: FC = () => {
  const [counter, setCounter] = useState<number>(5762);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  const handleClickStart = () => {
    analytics.track(TRACK_START_GROUP_CARD);
  };

  return (
    <Layout>
      <Container>
        <LeftSection>
          <Heading>privacy is a priority</Heading>
          <Paragraph>
            We <strong>keep all contributor notes private</strong> so you can write freely and
            openly to the recipient.
            <br />
            <br />
            Beyond that, we will never spam your inbox or sell your data to third parties. So what’s
            stopping you? Start a group card today.
          </Paragraph>
          <Link href={CREATE_CARD_LINK} onClick={handleClickStart}>
            start a group card
          </Link>
          <Info>
            <AvatarContainer>
              <Avatar1 imageUrl={imageUrl1} />
              <Avatar2 imageUrl={imageUrl2} />
              <Avatar3 imageUrl={imageUrl3} />
              <Avatar4 imageUrl={imageUrl4} />
              <Avatar5 imageUrl={imageUrl5} />
              <Avatar6 imageUrl={imageUrl6} />
              <Avatar7 imageUrl={imageUrl7} />
            </AvatarContainer>
            join Max & {counter.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} others
          </Info>
        </LeftSection>
        <RightSection>
          <CardB author='max' />
        </RightSection>
      </Container>
      <WaveEnhanced color='lightPeach2' />
    </Layout>
  );
};

export default PrivacyIsAPriority;
