import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Variants } from 'framer-motion';

import Card from './Card';
import ShapeImage from '../ShapeImage';

import star from '../../icons/star.svg';

const EnhancedCard = styled(Card)`
  width: 225px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 250px;
  }
`;

const Image = styled(ShapeImage)`
  height: 114px;
  width: 114px;
  align-self: center;
`;

const Author = styled.h3`
  margin-top: 8px;
  font-size: 18px;
  line-height: 1.55;
  color: ${(props) => props.theme.colors.blackText};
  text-align: center;
`;

const StarsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`;

const Star = styled.img`
  width: 20px;
  height: 20px;
`;

const Quote = styled.p`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.olive};
  text-align: justify;
`;

type Colors = 'pink' | 'blue' | 'olive';

const CardC: FC<{
  imageUrl: string;
  name: string;
  quote: string;
  color: Colors;
  className?: string;
  custom?: number;
  variants?: Variants;
}> = ({ imageUrl, quote, name, color, className, custom, variants }) => {
  const theme = useTheme();

  const getBackgroundColor = (color: Colors) => {
    switch (color) {
      case 'pink': {
        return { backgroundColor: `${theme.colors.pink}` };
      }
      case 'blue': {
        return { backgroundColor: `${theme.colors.cloudBlue}` };
      }
      case 'olive': {
        return { backgroundColor: `${theme.colors.lightOlive}` };
      }
      default: {
        return {};
      }
    }
  };

  return (
    <EnhancedCard
      style={getBackgroundColor(color)}
      className={className}
      custom={custom}
      variants={variants}
    >
      <Image imageUrl={imageUrl} />
      <Author>{name}</Author>
      <StarsContainer>
        <Star src={star} alt='5 stars' />
        <Star src={star} alt='' />
        <Star src={star} alt='' />
        <Star src={star} alt='' />
        <Star src={star} alt='' />
      </StarsContainer>
      <Quote>{quote}</Quote>
    </EnhancedCard>
  );
};

export default CardC;
