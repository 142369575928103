import React, { CSSProperties, FC, useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Layout = styled.section`
  display: flex;
  padding: 0 13%;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(props) => props.theme.colors.lightPeach2};
  align-items: center;
  position: relative;
  transition: background 0.3s ease;

  @media ${(props) => props.theme.breakpoints.laptop} {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0);
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
`;

const Quote = styled.p`
  font-weight: bold;
  font-size: 28px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.lightPeach2};

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 36px;
  }
`;

const Author = styled.h3`
  margin-top: 60px;
  font-weight: 900;
  font-size: 22px;
  line-height: 1.55;
  color: ${(props) => props.theme.colors.blackText};

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-weight: bold;
    font-size: 36px;
    line-height: 1.17;
  }
`;

const Title = styled.h4`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.36;
  font-style: italic;
  color: ${(props) => props.theme.colors.blackTextLight};

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 1.22;
  }
`;

const quoteLine1Variant = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      staggerChildren: 0.058,
      delayChildren: 0.5,
    },
  },
};

const quoteLine2Variant = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      staggerChildren: 0.04,
      delayChildren: 1,
    },
  },
};

const authorVariant = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0,
      staggerChildren: 0.09,
      delayChildren: 4.55,
    },
  },
};

const titleVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 5,
    },
  },
};

const letter = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.05 },
  },
};

const quoteLine1 = 'hey you–';
const quoteLine2 =
  'i know it hasn’t been easy times, but I hope you know that we’re cheering you on.';
const author = 'EMILY';
const title = 'from a graduate student to a peer';

const IntroSection: FC<{
  onAnimationComplete?: () => void;
  layoutStyle?: CSSProperties;
}> = ({ layoutStyle, onAnimationComplete = () => {} }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderLine = (line: string) => {
    return line.split('').map((char, index) => {
      return (
        <motion.span key={char + '-' + index} variants={letter}>
          {char}
        </motion.span>
      );
    });
  };

  return (
    <motion.div
      key='content'
      initial='open'
      animate={isCollapsed ? 'collapsed' : 'open'}
      variants={{
        open: { y: 0, display: 'block' },
        collapsed: { y: '-100%', display: 'none' },
      }}
      transition={{
        delay: 0.35,
        duration: 1.2,
        ease: 'anticipate',
        display: { delay: 1.3 },
      }}
    >
      <Layout style={layoutStyle}>
        <Center>
          <Quote>
            <motion.span
              variants={quoteLine1Variant}
              initial='hidden'
              animate='visible'
              style={{ display: 'block' }}
            >
              {renderLine(quoteLine1)}
            </motion.span>
            <motion.span
              variants={quoteLine2Variant}
              initial='hidden'
              animate='visible'
              style={{ display: 'block' }}
            >
              {renderLine(quoteLine2)}
            </motion.span>
          </Quote>
          <Author>
            <motion.span
              variants={authorVariant}
              initial='hidden'
              animate='visible'
              style={{ display: 'block' }}
            >
              {renderLine(author)}
            </motion.span>
          </Author>
          <Title>
            <motion.span
              variants={titleVariant}
              initial='hidden'
              animate='visible'
              style={{ display: 'block' }}
              onAnimationComplete={() => {
                setIsCollapsed(true);
                onAnimationComplete();
              }}
            >
              {title}
            </motion.span>
          </Title>
        </Center>
      </Layout>
    </motion.div>
  );
};

export default IntroSection;
